var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { encodeFunctionData, getContract, getCreate2Address, } from "viem";
import { ERC721TokenboundMech__factory } from "../../../typechain-types";
import { DEFAULT_SALT, ERC2470_SINGLETON_FACTORY_ABI, ERC2470_SINGLETON_FACTORY_ADDRESS, ERC6551_REGISTRY_ABI, ERC6551_REGISTRY_ADDRESS, } from "../constants";
import { erc6551ProxyBytecode } from "./factory";
export const calculateERC721TokenboundMechAddress = (context) => {
    return getCreate2Address({
        bytecode: erc6551ProxyBytecode(calculateERC721TokenboundMechMastercopyAddress(), context),
        from: context.from || ERC6551_REGISTRY_ADDRESS,
        salt: context.salt || DEFAULT_SALT,
    });
};
export const calculateERC721TokenboundMechMastercopyAddress = () => {
    return getCreate2Address({
        bytecode: ERC721TokenboundMech__factory.bytecode,
        from: ERC2470_SINGLETON_FACTORY_ADDRESS,
        salt: DEFAULT_SALT,
    });
};
export const makeERC721TokenboundMechDeployTransaction = ({ chainId, token, tokenId, salt = DEFAULT_SALT, from = ERC6551_REGISTRY_ADDRESS, }) => {
    return {
        to: from,
        data: encodeFunctionData({
            abi: ERC6551_REGISTRY_ABI,
            functionName: "createAccount",
            args: [
                calculateERC721TokenboundMechMastercopyAddress(),
                salt,
                chainId,
                token,
                tokenId,
            ],
        }),
    };
};
export const deployERC721TokenboundMech = (walletClient, { chainId, token, tokenId, salt = DEFAULT_SALT, from = ERC6551_REGISTRY_ADDRESS, }) => __awaiter(void 0, void 0, void 0, function* () {
    const { chain, account } = walletClient;
    if (!chain)
        throw new Error("No chain defined in walletClient");
    if (!account)
        throw new Error("No account defined in walletClient");
    const tokenChainId = chainId !== null && chainId !== void 0 ? chainId : chain.id;
    const transaction = makeERC721TokenboundMechDeployTransaction({
        chainId: tokenChainId,
        token,
        tokenId,
        salt,
        from,
    });
    return walletClient.sendTransaction(Object.assign(Object.assign({}, transaction), { account,
        chain }));
});
export const deployERC721TokenboundMechMastercopy = (walletClient) => __awaiter(void 0, void 0, void 0, function* () {
    const singletonFactory = getContract({
        address: ERC2470_SINGLETON_FACTORY_ADDRESS,
        abi: ERC2470_SINGLETON_FACTORY_ABI,
        walletClient,
    });
    return yield singletonFactory.write.deploy([ERC721TokenboundMech__factory.bytecode, DEFAULT_SALT], {
        gas: 2000000,
    });
});
