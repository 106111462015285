/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, ContractFactory, Interface, } from "ethers";
const _abi = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "test",
        outputs: [],
        stateMutability: "view",
        type: "function",
    },
];
const _bytecode = "0x60a060405234801561001057600080fd5b503060805260805160f461002d6000396000603f015260f46000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c8063f8a8fd6d14602d575b600080fd5b60336035565b005b6001600160a01b037f000000000000000000000000000000000000000000000000000000000000000016300360bc5760405162461bcd60e51b815260206004820152602360248201527f43616e206f6e6c792062652063616c6c6564207669612064656c656761746563604482015262185b1b60ea1b606482015260840160405180910390fd5b56fea2646970667358221220eeb8346d1061aced27d3008a67fbe055faa485cbb69a699dff1519c0ce935fae64736f6c63430008110033";
const isSuperArgs = (xs) => xs.length > 1;
export class DelegateCall__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    connect(runner) {
        return super.connect(runner);
    }
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
DelegateCall__factory.bytecode = _bytecode;
DelegateCall__factory.abi = _abi;
