/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, ContractFactory, Interface, } from "ethers";
const _abi = [
    {
        inputs: [],
        name: "CreateError",
        type: "error",
    },
];
const _bytecode = "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220bac1fd2a0025a9db2e8b3bfd68cbbbe5b3862d0a22a90b7dfb9689a088c15b9564736f6c63430008110033";
const isSuperArgs = (xs) => xs.length > 1;
export class MinimalProxyStore__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    connect(runner) {
        return super.connect(runner);
    }
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
MinimalProxyStore__factory.bytecode = _bytecode;
MinimalProxyStore__factory.abi = _abi;
