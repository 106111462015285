var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { concat, encodeAbiParameters, encodeFunctionData, getCreate2Address, keccak256, } from "viem";
import { MechFactory__factory, ZodiacMech__factory, } from "../../../typechain-types";
import { DEFAULT_SALT, ERC2470_SINGLETON_FACTORY_ADDRESS, MECH_FACTORY_ADDRESS, } from "../constants";
import { deployMastercopy, mechProxyBytecode } from "./factory";
export const calculateZodiacMechAddress = (
/** Addresses of the Zodiac modules */
modules, salt = DEFAULT_SALT) => {
    const initData = ZodiacMech__factory.createInterface().encodeFunctionData("setUp", [encodeAbiParameters([{ type: "address[]" }], [modules])]);
    return getCreate2Address({
        bytecode: mechProxyBytecode(calculateZodiacMechMastercopyAddress(), new Uint8Array(0)),
        from: MECH_FACTORY_ADDRESS,
        salt: keccak256(concat([keccak256(initData), salt])),
    });
};
const MASTERCOPY_INIT_DATA = [];
export const calculateZodiacMechMastercopyAddress = () => {
    const initData = encodeAbiParameters([{ type: "address[]" }], [MASTERCOPY_INIT_DATA]);
    return getCreate2Address({
        bytecode: (ZodiacMech__factory.bytecode +
            initData.slice(2)),
        from: ERC2470_SINGLETON_FACTORY_ADDRESS,
        salt: DEFAULT_SALT,
    });
};
export const makeZodiacMechDeployTransaction = ({ modules, salt = DEFAULT_SALT, }) => {
    const initCall = encodeFunctionData({
        abi: ZodiacMech__factory.abi,
        functionName: "setUp",
        args: [encodeAbiParameters([{ type: "address[]" }], [modules])],
    });
    return {
        to: MECH_FACTORY_ADDRESS,
        data: encodeFunctionData({
            abi: MechFactory__factory.abi,
            functionName: "deployMech",
            args: [calculateZodiacMechMastercopyAddress(), "0x", initCall, salt],
        }),
    };
};
export const deployZodiacMech = (walletClient, { modules, salt = DEFAULT_SALT, }) => __awaiter(void 0, void 0, void 0, function* () {
    const { chain, account } = walletClient;
    if (!chain)
        throw new Error("No chain defined in walletClient");
    if (!account)
        throw new Error("No account defined in walletClient");
    const transaction = makeZodiacMechDeployTransaction({ modules, salt });
    return walletClient.sendTransaction(Object.assign(Object.assign({}, transaction), { account,
        chain }));
});
export const deployZodiacMechMastercopy = (walletClient) => __awaiter(void 0, void 0, void 0, function* () {
    const initData = encodeAbiParameters([{ type: "address[]" }], [MASTERCOPY_INIT_DATA]);
    return yield deployMastercopy(walletClient, concat([ZodiacMech__factory.bytecode, initData]));
});
