var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { encodeAbiParameters, encodeFunctionData, getCreate2Address, } from "viem";
import { ERC1155ThresholdMech__factory, MechFactory__factory, } from "../../../typechain-types";
import { DEFAULT_SALT, ERC2470_SINGLETON_FACTORY_ADDRESS, MECH_FACTORY_ADDRESS, } from "../constants";
import { deployMastercopy, mechProxyBytecode } from "./factory";
export const calculateERC1155ThresholdMechAddress = ({ token, tokenIds, minBalances, minTotalBalance, salt = DEFAULT_SALT, }) => {
    const context = encodeAbiParameters([
        { type: "address" },
        { type: "uint256[]" },
        { type: "uint256[]" },
        { type: "uint256" },
    ], [token, tokenIds, minBalances, minTotalBalance]);
    return getCreate2Address({
        bytecode: mechProxyBytecode(calculateERC1155ThresholdMechMastercopyAddress(), context),
        from: MECH_FACTORY_ADDRESS,
        salt,
    });
};
export const calculateERC1155ThresholdMechMastercopyAddress = () => {
    return getCreate2Address({
        bytecode: ERC1155ThresholdMech__factory.bytecode,
        from: ERC2470_SINGLETON_FACTORY_ADDRESS,
        salt: DEFAULT_SALT,
    });
};
export const makeERC1155ThresholdMechDeployTransaction = ({ token, tokenIds, minBalances, minTotalBalance, salt = DEFAULT_SALT, }) => {
    const context = encodeAbiParameters([
        { type: "address" },
        { type: "uint256[]" },
        { type: "uint256[]" },
        { type: "uint256" },
    ], [token, tokenIds, minBalances, minTotalBalance]);
    return {
        to: MECH_FACTORY_ADDRESS,
        data: encodeFunctionData({
            abi: MechFactory__factory.abi,
            functionName: "deployMech",
            args: [
                calculateERC1155ThresholdMechMastercopyAddress(),
                context,
                "0x",
                salt,
            ],
        }),
    };
};
export const deployERC1155ThresholdMech = (walletClient, { token, tokenIds, minBalances, minTotalBalance, salt = DEFAULT_SALT, }) => __awaiter(void 0, void 0, void 0, function* () {
    const { chain, account } = walletClient;
    if (!chain)
        throw new Error("No chain defined in walletClient");
    if (!account)
        throw new Error("No account defined in walletClient");
    const transaction = makeERC1155ThresholdMechDeployTransaction({
        token,
        tokenIds,
        minBalances,
        minTotalBalance,
        salt,
    });
    return walletClient.sendTransaction(Object.assign(Object.assign({}, transaction), { account,
        chain }));
});
export const deployERC1155ThresholdMechMastercopy = (walletClient) => __awaiter(void 0, void 0, void 0, function* () {
    return yield deployMastercopy(walletClient, ERC1155ThresholdMech__factory.bytecode);
});
