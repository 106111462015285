var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { concat, encodeAbiParameters, getContract, parseEther, size, } from "viem";
import { MechFactory__factory } from "../../../typechain-types";
import { DEFAULT_SALT, ERC2470_SINGLETON_FACTORY_ABI, ERC2470_SINGLETON_FACTORY_ADDRESS, } from "../constants";
export const mechProxyBytecode = (implementation, context) => {
    if (implementation.length !== 42) {
        throw new Error(`Invalid implementation address: ${implementation}`);
    }
    return concat([
        "0x3d61",
        encodeAbiParameters([{ type: "uint16" }], [0x2d + size(context) + 1]),
        "0x8060",
        encodeAbiParameters([{ type: "uint8" }], [0x0a + 1]),
        "0x3d3981f3363d3d373d3d3d363d73",
        implementation,
        "0x5af43d82803e903d91602b57fd5bf3",
        "0x00",
        context, // appended context data
    ]);
};
export const erc6551ProxyBytecode = (implementation, { chainId, token, tokenId, salt = DEFAULT_SALT, }) => {
    if (implementation.length !== 42) {
        throw new Error(`Invalid implementation address: ${implementation}`);
    }
    return concat([
        "0x3d60ad80600a3d3981f3363d3d373d3d3d363d73",
        implementation,
        "0x5af43d82803e903d91602b57fd5bf3",
        encodeAbiParameters([
            { type: "uint256" },
            { type: "uint256" },
            { type: "address" },
            { type: "uint256" },
        ], [BigInt(salt), BigInt(chainId), token, tokenId]),
    ]);
};
/**
 * Deploy a mastercopy via the ERC-2470 singleton factory.
 */
export const deployMastercopy = (walletClient, bytecode) => __awaiter(void 0, void 0, void 0, function* () {
    const singletonFactory = getContract({
        address: ERC2470_SINGLETON_FACTORY_ADDRESS,
        abi: ERC2470_SINGLETON_FACTORY_ABI,
        walletClient,
    });
    return yield singletonFactory.write.deploy([bytecode, DEFAULT_SALT], {
        gas: 10000000,
    });
});
/**
 * Deploy MechFactory via the ERC-2470 singleton factory.
 */
export const deployMechFactory = (walletClient) => __awaiter(void 0, void 0, void 0, function* () {
    return yield deployMastercopy(walletClient, MechFactory__factory.bytecode);
});
/**
 * Deploy the ERC-2470 singleton factory on networks where it doesn't exist yet.
 */
export const deployERC2470SingletonFactory = (walletClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!walletClient.account) {
        throw new Error("walletClient.account is undefined");
    }
    yield walletClient.sendTransaction({
        account: walletClient.account,
        chain: walletClient.chain,
        to: "0xBb6e024b9cFFACB947A71991E386681B1Cd1477D",
        value: parseEther("0.0247"),
    });
    yield walletClient.request({
        method: "eth_sendRawTransaction",
        params: [
            "0xf9016c8085174876e8008303c4d88080b90154608060405234801561001057600080fd5b50610134806100206000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c80634af63f0214602d575b600080fd5b60cf60048036036040811015604157600080fd5b810190602081018135640100000000811115605b57600080fd5b820183602082011115606c57600080fd5b80359060200191846001830284011164010000000083111715608d57600080fd5b91908080601f016020809104026020016040519081016040528093929190818152602001838380828437600092019190915250929550509135925060eb915050565b604080516001600160a01b039092168252519081900360200190f35b6000818351602085016000f5939250505056fea26469706673582212206b44f8a82cb6b156bfcc3dc6aadd6df4eefd204bc928a4397fd15dacf6d5320564736f6c634300060200331b83247000822470",
        ],
    });
});
