/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, ContractFactory, Interface, } from "ethers";
const _abi = [
    {
        inputs: [],
        name: "AccountCreationFailed",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "salt",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "chainId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "tokenContract",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "ERC6551AccountCreated",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "implementation",
                type: "address",
            },
            {
                internalType: "bytes32",
                name: "salt",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "chainId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "tokenContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "account",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "implementation",
                type: "address",
            },
            {
                internalType: "bytes32",
                name: "salt",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "chainId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "tokenContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "createAccount",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
];
const _bytecode = "0x608060405234801561001057600080fd5b5061033d806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c8063246a00211461003b5780638a54c52f1461006a575b600080fd5b61004e610049366004610238565b61007d565b6040516001600160a01b03909116815260200160405180910390f35b61004e610078366004610238565b6100ad565b60008061008d8787878787610175565b8051906020012090506100a086826101de565b9150505b95945050505050565b6000806100bd8787878787610175565b905060006100d28783805190602001206101de565b90506001600160a01b0381163b156100ed5791506100a49050565b604080516001600160a01b038381168252602082018a9052918101889052859180881691908b16907f79f19b3655ee38b1ce526556b7731a20c8f218fbda4a3990b6cc4172fdf887229060600160405180910390a4868251602084016000f590506001600160a01b0381166100a0576040516320188a5960e01b815260040160405180910390fd5b60408051602081018690529081018490526001600160a01b0383166060828101919091526080820183905290869060a00160408051601f19818403018152908290526101c49291602001610286565b604051602081830303815290604052905095945050505050565b60006101eb8383306101f2565b9392505050565b6000604051836040820152846020820152828152600b8101905060ff815360559020949350505050565b80356001600160a01b038116811461023357600080fd5b919050565b600080600080600060a0868803121561025057600080fd5b6102598661021c565b945060208601359350604086013592506102756060870161021c565b949793965091946080013592915050565b733d60ad80600a3d3981f3363d3d373d3d3d363d7360601b8152606083901b6bffffffffffffffffffffffff191660148201526e5af43d82803e903d91602b57fd5bf360881b60288201528151600090815b818110156102f557602081860181015160378684010152016102d8565b5060009201603701918252509291505056fea26469706673582212206e0f421ddc4d394c423ac1cd318a607e0844e6f43d3b008b68592cc86f4e4e8364736f6c63430008110033";
const isSuperArgs = (xs) => xs.length > 1;
export class ERC6551Registry__factory extends ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    connect(runner) {
        return super.connect(runner);
    }
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
ERC6551Registry__factory.bytecode = _bytecode;
ERC6551Registry__factory.abi = _abi;
